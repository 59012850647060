/* CONTENTS */

body#visual-portal-wrapper.pat-plone .outer-wrapper {
  [class*="contenttype-"]:before {
    font-family:"Fontello"; font-size: 100%; 
    padding: 0; margin:0; position: relative; left: inherit; display: inline-block; color: inherit;
    width: 20px; height: 20px; text-align: center; margin-right: @plone-padding-base-vertical;
  }
  .contenttype-thema:before {  content: '\e820';}
  .contenttype-project:before {  content: '\e80e';}
  .contenttype-homepage:before {    content: '\e811';}
  .contenttype-subthema:before {content: '\e819';}
  .contenttype-programma:before {    content: '\e830';}
  .contenttype-city:before {    content: '\e81e';}
  .contenttype-cityfolder:before {    content: '\e801';}
}

/* on right on toolbar */
body#visual-portal-wrapper.pat-plone #edit-zone {
  [class*="contenttype-"] {padding: 5px 15px 5px 50px;}

  [class*="contenttype-"]:after {
    font-family:"Fontello"; font-size: 100%; 
    padding: 0; margin:0; position: relative; left:  25px; display: inline-block; color: inherit;
    width: 20px; height: 20px; text-align: center; margin-right: @plone-padding-base-vertical;
    position:absolute;
  }
  .contenttype-thema:after {    content: '\e820';}
  .contenttype-project:after {  content: '\e80e';}
  .contenttype-homepage:after {    content: '\e811';}
  .contenttype-subthema:after {  content: '\e819';}
  .contenttype-programma:after {    content: '\e830';}
  .contenttype-city:after {    content: '\e81e';}
  .contenttype-cityfolder:after {    content: '\e801';}
}