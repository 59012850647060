.gradient(@type; @colors; @dir: 0deg; @prefixes: webkit, moz, ms, o; @index: length(@prefixes)) when (@index > 0) {
    .gradient(@type; @colors; @dir; @prefixes; (@index - 1));
    @prefix  : extract(@prefixes, @index ); 
    @dir-old : 90 - (@dir);
    background-image: ~"-@{prefix}-@{type}-gradient(@{dir-old}, @{colors})";
    & when ( @index = length(@prefixes) ) {
      background-image: ~"@{type}-gradient(@{dir}, @{colors})";
    }
}

.border-radius(@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius:    @radius;
  border-radius:         @radius;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

.drop-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow:  @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow:  @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow:  @x @y @blur @spread rgba(0, 0, 0, @alpha);
}