/*
// Homepage Slider Style
*/ 
.principal {
  position: relative;
  padding:0;
  width: 100%;
}
.cd-hero {
  position: relative;
  width: 100%;
  
  ul {
    padding:0;
    margin:0;
  }
  h2, p {
    margin-top:0;
    margin-left:40px;
    margin-bottom:30px;
    color:white;
  }

}
.cd-hero-slider {
  position: relative;
  height: 360px;
  overflow: hidden;
  padding:100px;

  li {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding:0;
    margin:0;
    display:block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);

    .cd-full-width {
      width:768px;

      .slider-text {
        margin:0;
        padding:20px 0;
        position:absolute;
        bottom:0px;
        left:0px;
        width:780px; //mb
        height:200px;
        background: rgba(0,0,0,0);
        .gradient(linear; rgba(0,0,0,0), rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.65) 100%; 180deg);
        h2 {
          margin:0 30px;
          margin-bottom: 30px;
          text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 3px;
        }
        h3 {
          margin:0 30px;
          padding-bottom:5px;
          color:#fff;
          font-weight:bold;
          line-height:1.2em;
          font-size:16px;
        }
        p {
          bottom:0;
          margin:0 30px;
          font-size:14px;
          color:#fff;
          height:60px;
          // width:600px;
          // white-space:nowrap;
          overflow: hidden;
          // text-overflow: ellipsis;
          .date {
            font-size:1.2em;
            padding: 0 10px 0 0;
            border: 0;
          }
        }
      }

    }
    
  }
  li.selected {
    /* this is the visible slide */
    position: relative;
    transform: translateY(0);
  }

  li.move-left {
    /* slide hidden on the left */
    transform: translateY(-100%);
  }

  li.is-moving, 
  li.selected {
    /* the is-moving class is assigned to the slide which is moving outside the viewport */
    transition: transform 0.5s;
  }
}



@media only screen and (min-width: 768px) {
  .cd-hero-slider {
    .cd-half-width {
      opacity: 0;
      transform: translateX(40px);
    }
    .move-left .cd-half-width {
      transform: translateX(-40px);
    }
    .selected .cd-half-width {
      /* this is the visible slide */
      opacity: 1;
      transform: translateX(0);
    }
    .is-moving .cd-half-width {
      /* this is the slide moving outside the viewport 
      wait for the end of the transition on the <li> parent 
      before set opacity to 0 and translate to 40px/-40px */
      transition: opacity 0s 0.5s, transform 0s 0.5s;
    }
    li.selected.from-left {
      .cd-half-width:first-of-type,
      .cd-half-width:nth-of-type(2) {
        transition: opacity 0.4s 0.2s, transform 0.5s 0.2s;
      }
      .cd-half-width:first-of-type,
      .cd-half-width:nth-of-type(2) {
        /* this is the selected slide - different animation 
        if it's entering from left or right */
        transition: opacity 0.4s 0.4s, transform 0.5s 0.4s;
      }
    }
  }
}

.cd-slider-nav {
  position: absolute;
  top: 0;
  right:0;
  z-index: 2;
  text-align: left;
  background-color: rgba(20,50, 30, 0.5);
  height:360px;

  nav {
    display: block;
    position: relative;
    height:100%;
    // width:360px;
    ul, li {
      width:360px;
    }
    a {
      // width:360px;
      &:hover {
        text-decoration:none;
      }
    }
  }
  .cd-marker {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 120px;
    color: #d44457;
    background-color: rgba(250, 255, 255, 0.35);
    // background-color: rgba(0, 1, 1, 0.3);
    // box-shadow: inset 0 2px 0 currentColor;
    -webkit-transition: -webkit-transform 0.2s, box-shadow 0.2s;
    -moz-transition: -moz-transform 0.2s, box-shadow 0.2s;
    transition: transform 0.2s, box-shadow 0.2s;
    &.item-2 {
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -o-transform: translateY(100%);
      transform: translateY(100%);
    }
    &.item-3 {
      -webkit-transform: translateY(200%);
      -moz-transform: translateY(200%);
      -ms-transform: translateY(200%);
      -o-transform: translateY(200%);
      transform: translateY(200%);
    }
    // &.item-4 {
    //   -webkit-transform: translateX(300%);
    //   -moz-transform: translateX(300%);
    //   -ms-transform: translateX(300%);
    //   -o-transform: translateX(300%);
    //   transform: translateX(300%);
    // }
    // &.item-5 {
    //   -webkit-transform: translateX(400%);
    //   -moz-transform: translateX(400%);
    //   -ms-transform: translateX(400%);
    //   -o-transform: translateX(400%);
    //   transform: translateX(400%);
    // }
    
  }

}

.cd-slider-nav ul::after {
  clear: both;
  content: "";
  display: table;
}
.cd-slider-nav li {
  display: block;
  width: 360px;
  padding:0;
  // float: left;
  border-bottom:1px dotted #333;

  &:last-child {
    border:0;
  }
  
}
.cd-slider-nav li.selected a {
  // color: #2c343b;
  // border-left: 1px solid white;
  color:#fff;
}
.no-touch .cd-slider-nav li.selected a:hover {
  background-color: transparent;
}
.cd-slider-nav a {
  display: block;
  height: 120px;
  position: relative;
  padding: 15px 0;
  font-size: 1rem;
  line-height:1.1rem;
  font-weight: 900;
  text-decoration:none;
  // color: #a8b4be;
  color:#fff;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
  &:hover {
    // background-color: rgba(250, 255, 255, 0.3);
    color:#fff;
  }

  div {
    h3 {
      position:absolute;
      display:block;
      width:340px;
      height:80px;
      overflow:hidden;
      left:10px;
      top:10px;
      font-size:18px;
      line-height:2.3rem;
      padding:0;
      margin:0;
      font-weight:bold;
      // margin:0 10px
    }
    p {
      position:absolute;
      display:block;
      left:10px;
      bottom:10px;
      width:340px;
      height:20px;
      font-size:11px;
      color:#fff;
      padding:0;
      margin:0;
      // padding:5px 0 0 0;
      // margin: 0 10px;
    }
  }
}
// .cd-slider-nav a::before {
//   content: '';
//   position: absolute;
//   width: 24px;
//   height: 24px;
//   top: 8px;
//   left: 50%;
//   right: auto;
//   -webkit-transform: translateX(-50%);
//   -moz-transform: translateX(-50%);
//   -ms-transform: translateX(-50%);
//   -o-transform: translateX(-50%);
//   transform: translateX(-50%);
//   background: url(/++resource++atopia_theme.exhem/cd-icon-navigation.svg) no-repeat 0 0;
// }
// .no-touch .cd-slider-nav a:hover {
//   background-color: rgba(0, 1, 1, 0.5);
// }
// .cd-slider-nav li:first-of-type a::before {
//   background-position: 0 0;
// }
// .cd-slider-nav li.selected:first-of-type a::before {
//   background-position: 0 -24px;
// }
// .cd-slider-nav li:nth-of-type(2) a::before {
//   background-position: -24px 0;
// }
// .cd-slider-nav li.selected:nth-of-type(2) a::before {
//   background-position: -24px -24px;
// }
// .cd-slider-nav li:nth-of-type(3) a::before {
//   background-position: -48px 0;
// }
// .cd-slider-nav li.selected:nth-of-type(3) a::before {
//   background-position: -48px -24px;
// }
// .cd-slider-nav li:nth-of-type(4) a::before {
//   background-position: -72px 0;
// }
// .cd-slider-nav li.selected:nth-of-type(4) a::before {
//   background-position: -72px -24px;
// }
// .cd-slider-nav li:nth-of-type(5) a::before {
//   background-position: -96px 0;
// }
// .cd-slider-nav li.selected:nth-of-type(5) a::before {
//   background-position: -96px -24px;
// }

@media only screen and (min-width: 768px) {
  .cd-slider-nav {
    // height: 80px;
  }
  .cd-slider-nav .cd-marker,
  .cd-slider-nav li {
    // width: 95px;
  }
  .cd-slider-nav a {
    // padding-top: 48px;
    font-size: 1.1rem;
    // text-transform: uppercase;
  }
  .cd-slider-nav a::before {
    // top: 18px;
  }
  .cd-hero-slider li .cd-full-width .slider-text {
    height:250px;
  }
  #mc_embed_signup {
    border-left: 1px solid #ccc;
  }

}

@media only screen and (max-width:767px) {
  #mc_embed_signup {
    border-left:0 !important;
  }
  .cd-slider-nav {
    display:none;
  }
}