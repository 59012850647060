/* our custom styles */

// DELETE ME:
.custom-theme-marker {
    display: none;
    position: absolute;
    top: 300px;
    left: 30%;
    margin: auto 0;
    transform: rotate(-20deg);
    background: #00ECFF;
    color: #fff;
    padding: 1em;
    z-index: 100;
}
.section-front-page .custom-theme-marker{
    display: block;
}

.principal {
  background-image:none;
  background: rgba(51,51,51,1);
}

.principal .container{
  // width: 1140px; // loose width on smaller screens
  padding: 0 0px; // dump the padding on smaller screens
  box-shadow: -50px 0px 100px -50px rgba(0, 0, 0, 0.5), 50px 0px 100px -50px rgba(0, 0, 0, 0.5);
  background: rgba(51,51,51,1);//rgba(0, 0, 0, 0.65)
  background: -moz-linear-gradient(left, rgba(51,51,51,1) 0%, rgba(0,0,0,1) 25%, rgba(0,0,0,1) 75%, rgba(51,51,51,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(51,51,51,1) 0%,rgba(0,0,0,1) 25%,rgba(0,0,0,1) 75%,rgba(51,51,51,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(51,51,51,1) 0%,rgba(0,0,0,1) 25%,rgba(0,0,0,1) 75%,rgba(51,51,51,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}



/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

#parent-fieldname-text {
  padding-top:10px;
  margin-top:40px;
  border-top:1px solid #ccc;
}
.template-full_view #parent-fieldname-text {
  padding-top:0;
  margin-top:0;
  border-top:0;
  border-bottom:1px solid #ccc;
  padding-bottom:20px;
}

//headers
h1, h2, h3, 
.h1, .h2, .h3 {
  font-weight:300;
  a {
    &:hover, 
    &:focus { 
      text-decoration: none; 
      border-bottom: 1px @plone-link-hover-color dotted; 
    }
  }
}

// Links
a {
  color: @plone-link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: @plone-link-hover-color;
    text-decoration: underline;
  }

  &:focus {
    .tab-focus();
  }
}
.modal-image {
  text-align: center;
}

/* hiddin toolbar */
body {
  &.plone-toolbar-top-default,
  &.plone-toolbar-top-expanded {
    margin-top:0;
  }
  &.plone-toolbar-left-default,
  &.plone-toolbar-left-expanded {
    padding-left:0;
  }
}

body {
  &.userrole-contributor.plone-toolbar-top-default,
  &.userrole-editor.plone-toolbar-top-default,
  &.userrole-reviewer.plone-toolbar-top-default,
  &.userrole-manager.plone-toolbar-top-default,
  &.userrole-owner.plone-toolbar-top-default {
    margin-top:60px;
  }
  &.userrole-contributor.plone-toolbar-top-expanded,
  &.userrole-editor.plone-toolbar-top-expanded,
  &.userrole-reviewer.plone-toolbar-top-expanded,
  &.userrole-manager.plone-toolbar-top-expanded,
  &.userrole-owner.plone-toolbar-top-expanded {
    margin-top:60px;
  }
  &.userrole-contributor.plone-toolbar-left-default,
  &.userrole-editor.plone-toolbar-left-default,
  &.userrole-reviewer.plone-toolbar-left-default,
  &.userrole-manager.plone-toolbar-left-default,
  &.userrole-owner.plone-toolbar-left-default {
    padding-left:60px;
  }
  &.userrole-contributor.plone-toolbar-left-expanded,
  &.userrole-editor.plone-toolbar-left-expanded,
  &.userrole-reviewer.plone-toolbar-left-expanded,
  &.userrole-manager.plone-toolbar-left-expanded,
  &.userrole-owner.plone-toolbar-left-expanded {
    padding-left:120px;
  }
}
.image-large {
  margin:10px auto;
  background-repeat:none;
  background-position:center center;
  background-size:cover;
}

@media (min-width: 768px) {
  .image-large {
    height:360px;
    width:100%;
  }
  .principal .container {
    // width:1140px;
  }

  #portal-header #portal-searchbox {
    margin-top:20px;
  }
  #portal-header #portal-anontools {
    margin-top:15px;
  }
  .plone-nav > li > a:hover, 
  .plone-nav > li > a:focus {
      text-decoration: none;
      background-color: #214d48;
      .transition(background-color .3s linear);
  }
  #portal-header #portal-logo {
    float: left;
    padding: 30px 0;
    margin: -30px 0;
    background: url(/++resource++atopia_theme.exhem/logo-exhem-bg.svg) no-repeat 0px 20px;
    // logo in rules.xml
  }
}

@media (max-width: 769px) {
  #portal-searchbox {
    display:none;
  }
  .image-large {
    height:250px;
    width:100%;
  }
  
  body {
    &.plone-toolbar-top-default,
    &.plone-toolbar-top-expanded,
    &.plone-toolbar-left-default,
    &.plone-toolbar-left-expanded,
    &.userrole-contributor.plone-toolbar-top-default,
    &.userrole-contributor.plone-toolbar-top-expanded,
    &.userrole-contributor.plone-toolbar-left-default,
    &.userrole-contributor.plone-toolbar-left-expanded,
    &.userrole-editor.plone-toolbar-top-default,
    &.userrole-editor.plone-toolbar-top-expanded,
    &.userrole-editor.plone-toolbar-left-default,
    &.userrole-editor.plone-toolbar-left-expanded,
    &.userrole-reviewer.plone-toolbar-top-default,
    &.userrole-reviewer.plone-toolbar-top-expanded,
    &.userrole-reviewer.plone-toolbar-left-default,
    &.userrole-reviewer.plone-toolbar-left-expanded,
    &.userrole-manager.plone-toolbar-top-default,
    &.userrole-manager.plone-toolbar-top-expanded,
    &.userrole-manager.plone-toolbar-left-default,
    &.userrole-manager.plone-toolbar-left-expanded,
    &.userrole-owner.plone-toolbar-top-default,
    &.userrole-owner.plone-toolbar-top-expanded,
    &.userrole-owner.plone-toolbar-left-default,
    &.userrole-owner.plone-toolbar-left-expanded {
      margin-top:0;
      padding-left:0;
    }
  }
  
}
.principal .container {
  // width:100%;
}
.homepage_list {
  a {
    &:hover { 
      text-decoration:none;
    }
    h4 {
      margin-top:0;
    }
  }
  ul {
    list-style:none;
    padding-left:0;
    li {
      border-bottom:1px solid #ccc;
      padding-top:10px;
      clear:left;
      &:last-child{
        // border:0;
        padding-top:10px;
        // text-align:right;
      }
      .tile {
        width:80px;
        height:60px;
        background-size:cover;
        display:inline-block;
        float:left;
        margin-right:10px;
      }
    }
  }
}

.subthema {
  background-size:cover;
  margin:10px 0;
  overflow:hidden;
  display:table;
  width:100%;
  .border-radius(6px);
  .item {
    height:90px;
    display:table-cell;
    vertical-align:middle;
    padding:0px 5px;
    text-align:center;
    .border-radius(6px);
    a {
      color:#fff;
      font-weight:700;
      font-size:1.1em;
      text-transform:uppercase;
      line-height:1.1em;
      &:hover {
        text-decoration:none;
      }
    }
  }
  &.btn_a {
    .item {
      .gradient(linear;rgba(0,102,102,0.6),rgba(0,102,102,0.8));
      &:hover {
        .gradient(linear;rgba(0,102,102,0.7),rgba(0,102,102,1));
      }
    }
  }
  &.btn_b {
    .item {
      .gradient(linear;rgba(153,51,0,0.6),rgba(153,51,0,0.8));
      &:hover {
        .gradient(linear;rgba(153,51,0,0.7),rgba(153,51,0,1));
      }
    }
  }
  &.btn_c {
    .item {
      .gradient(linear;rgba(0,102,153,0.6),rgba(0,102,153,0.8));
      &:hover {
        .gradient(linear;rgba(0,102,153,0.7),rgba(0,102,153,1));
      }
    }
  }
  &.btn_d {
    .item {
      .gradient(linear;rgba(153,51,102,0.6),rgba(153,51,102,0.8));
      &:hover {
        .gradient(linear;rgba(153,51,102,0.7),rgba(153,51,102,1));
      }
    }
  }
  &.btn_e {
    .item {
      .gradient(linear;rgba(204,102,0,0.6),rgba(204,102,0,0.8));
      &:hover {
        .gradient(linear;rgba(204,102,0,0.7),rgba(204,102,0,1));
      }
    }
  }
  &.btn_f {
    .item {
      .gradient(linear;rgba(0,51,102,0.6),rgba(0,51,102,0.8));
      &:hover {
        .gradient(linear;rgba(0,51,102,0.7),rgba(0,51,102,1));
      }
    }
  }
}


.portlet.project_portlet {
  border:none;
  box-shadow:none;
  .portletHeader{
    font-size:0.8em;
    text-transform:uppercase;
    color:#ccc;
    letter-spacing:2px;
    padding-left:0;
    margin-bottom:10px;
    border:none;
    background:none;
    border-bottom:1px solid #ccc;  
  }
  .portletContent {
    border:none;
    background:none;
    .portletItem {
      padding:0;
      .event.summary {
        box-shadow:none;
        padding:0;
      }
    }      
  }
  .portletFooter { 
    box-shadow:none;
    border-top:none;
  }
}




//*// FOOTER //*//

#portal-footer-wrapper {
  padding: 30px 15px;
  background-color: rgba(251,251,251,1);
  color: #777;
  text-align: left;
  clear: both;
  .gradient(linear; rgba(200,200,200,1), rgba(241,241,241,1) 6%, rgba(251,251,251,1) 100%; 180deg);
  
  p {
    border-bottom: 1px solid @plone-gray-lighter;
    padding-bottom: 28px;
  }
  a {
    text-transform:uppercase;
    font-size:0.95em;
    font-weight:500;
    color: #777;
    &:hover {
      color:#333;
      text-decoration:none;
    }    
  }
  .portletWrapper {
    display: inline-block;
    padding-top:20px;
    &:first-child {
      display: block;
      border-bottom: 0;
      padding-bottom: 1em;
      margin-bottom: .5em;
      float:left;
    }
    &:last-child {
      float:right;
    }
  }
  .portlet {
    background: transparent;
    border: 0;
    font-weight: @plone-font-weight-light;
    box-shadow: none;
    margin-bottom: 0;
    .portletContent {
      padding: 0;
      background: transparent;
      border: 0;
      > * {padding: inherit;}
      li {
        background: transparent;
        display: inline-block;
        border: 0;
        white-space: nowrap;
        padding: 0 30px 10px 0;    
      }
    }
    &#portal-colophon .portletContent{ 
      a {
        padding: 0;
      }
    }
    .portletItem:before, .portletItem a:before {
      content: "";
    }
    .portletItem a {
      padding: 0; border:0;
    }
    .portletActions {
      text-transform: uppercase;
      font-size: @plone-font-size-small;
      font-weight: @plone-font-weight-bold;
      margin-right: 15px;
    }
  }
}

#portal-siteactions {
  display: inline-block;
  text-transform: uppercase;
  font-size: @plone-font-size-small;
}
#portal-siteactions li {
  font-weight: bold;
  display: inline-block;
  margin-left: 30px;
  &:first-child {
    margin-left: 0;
  }
}


.plone-navbar-nav {
  > .selected > a {
    &,
    &:hover,
    &:focus {
      background-color: lighten(@plone-sitenav-link-hover-bg,15%);
    }
  }
}

#related-projects {
  margin-bottom:35px;
  .portletHeader{
    padding: 8px 15px;
  }
  .portletContent {
    border-top:1px solid #ccc;
    h3 {
      margin-top: 10px;
      margin-bottom: 5px;
      // text-transform:uppercase;
      font-size:1.5em;
      font-weight:300;
      // letter-spacing:1px;
    }
    ul {
      li {
        // border-bottom:1px solid #ccc;
        padding-bottom:0px;
        clear:left;
        &:last-child{
          border:0;
          padding-top:10px;
        }
        a {
          padding: 0px 10px 0px 20px;
          &.link-subtheme {
            .link-subtheme;
          }
          &:hover {
            text-decoration:none; 
          }
          &:before{
            content:none;
            left:0;
          }
        }
        p {
          margin-left:20px;
        }
      }
    }
  }
}
#content {
  margin-bottom:35px;
}

.link-subtheme {
  font-size:0.9em;
  border:1px solid #ddd;
  color:#333;
  border-radius: @border-radius-small;
  padding:0px 8px 2px 8px;
  margin-right: 2px;
  background: #fff;
  display:inline;
  white-space:nowrap;
  &:hover{
    color:#fff;
    text-decoration:none;
    background: lighten(@plone-sitenav-bg,10%);
  }
  &.no-link {
    color:#999;
    background: #ececec;
    border:1px solid #ddd;
  }
}

.project-subthemes {
  ul {
    padding-left:0;
    li {
      list-style:none;
      .link-subtheme{
        display:inline-block;
      }
    }
  }
}
